import { addPropertyControls, ControlType } from "framer"
import React from "react"

function colorToHex(color) {
    if (!color) return ""

    // If color is already in hex format
    if (color.startsWith("#")) {
        return color.substring(1)
    }

    // If color is in RGB format, convert it to hex
    const rgbMatch = color.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)
    if (rgbMatch) {
        const [r, g, b] = rgbMatch.slice(1).map(Number)
        return ((r << 16) + (g << 8) + b).toString(16).padStart(6, "0")
    }

    return ""
}

export default function SVGLogo(props) {
    const iconName = props.name.toLowerCase().replace(/\s+/g, "-")
    const colorHex = props.theme === "Custom" ? colorToHex(props.color) : ""

    const svgUrl = `https://cdn.simpleicons.org/${iconName}${
        colorHex ? `/${colorHex}` : ""
    }`

    const imgStyle = {
        width: props.size,
        height: props.size,
    }

    return <img src={svgUrl} style={imgStyle} alt={props.name} />
}

SVGLogo.displayName = "SVGLogo"

addPropertyControls(SVGLogo, {
    name: {
        type: ControlType.String,
        title: "Name",
        defaultValue: "framer",
    },
    theme: {
        type: ControlType.Enum,
        title: "Theme",
        options: ["Brand", "Custom"],
        optionTitles: ["Brand", "Custom"],
        defaultValue: "Brand",
        displaySegmentedControl: true,
    },
    color: {
        type: ControlType.Color,
        title: "Color",
        defaultValue: "#ffffff",
        hidden: (props) => props.theme === "Default",
    },
    size: {
        type: ControlType.Number,
        title: "Size",
        defaultValue: 40,
        min: 8,
        max: 200,
        unit: "px",
        description: "⤷ [simpleicons.org](https://simpleicons.org)",
    },
})
